<template>
  <div>
    <vx-card>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h3 class="mt-4 mb-4">
          Edición {{ $tc("message.parking", 1, user.countryCode) }}
        </h3>
      </vs-col>
      <br />
      <div class="p-2">
        <vs-row>
          <vs-col>
            <vs-chip color="success" class="m-2">Paso 1</vs-chip>
            <div>
              <h6 class="mt-3">Información basica:</h6>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider></vs-divider>
      </div>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-tag"
              :label="`Identificador o número de ${$tc(
                'message.parking',
                1,
                user.countryCode
              )}`"
              color="primary"
              v-model="unitNumber"
              v-validate="'required'"
              name="Nombre_Proyecto"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-1">
            <p class="vs-input--label">Seleccione estado:</p>
            <v-select
              class="px-2"
              v-model="status"
              :options="propertiesStatus"
              v-if="activeModifyStatus"
            ></v-select>
            <vs-input
              v-else
              size="large"
              class="w-full"
              color="primary"
              :value="status"
              v-validate="'required'"
              disabled
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-square-full"
              label="Metraje"
              color="primary"
              type="number"
              min="0"
              v-model="squareMeters"
              v-validate="'required'"
              name="Metraje"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-building"
              label="Número de torre"
              v-model="towerNumber"
              name="towerNumber"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <p class="vs-input--label">
              Seleccione tipo de
              {{ $tc("message.parking", 1, user.countryCode) }}:
            </p>
            <v-select
              class="px-2"
              v-model="type"
              :options="attributesTypes"
            ></v-select>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              label="Nivel"
              color="primary"
              v-model="level"
              name="Nivel"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-money-bill"
              label="Precio - Ingresar valor sin comas"
              color="primary"
              type="number"
              min="0"
              v-model="price"
              v-validate="'required'"
              name="Precio"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col>
          <br />
          <vs-row>
            <vs-col class="sm:w-full lg:w-full p-1">
              <vs-button
                color="success"
                @click="updateProperty()"
                :disabled="!fieldsReady"
                icon-pack="feather"
                icon="icon-save"
                type="filled"
                ref="loadableButton"
                id="button-with-loading"
                class="vs-con-loading__container w-full"
                >Guardar {{ $tc("message.parking", 1, user.countryCode) }}
                {{ unitNumber }}</vs-button
              >
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import api from "../services/property.api";
import { capitalize } from "../../../helpers/capitalize";

export default {
  data() {
    return {
      propertiesStatus: [],
      property: null,
      status: "",
      unitNumber: "",
      price: 0,
      squareMeters: 0,
      type: "",
      attributesTypes: [],
      towerNumber: "",
      level: 0,
      activeModifyStatus: true,
    };
  },
  components: {
    "v-select": vSelect,
  },
  async mounted() {
    const propertiesStatus = await api.getPropertyStatus();
    const attributesTypes = await api.getPropertyAttributeValue("PARKING_TYPE");
    if (attributesTypes.length) {
      this.attributesTypes = attributesTypes[0].values.map((obj) => {
        return { label: obj.label, value: obj.value };
      });
    }

    this.propertiesStatus = propertiesStatus.filter(
      (status) => status.id === "AVAILABLE" || status.id === "BLOCKED"
    );

    this.propertiesStatus = this.propertiesStatus.map((obj) => {
      return { label: obj.name, value: obj.id };
    });
  },
  computed: {
    ...mapState("auth", ["user"]),
    fieldsReady() {
      if (
        !this.status ||
        !this.unitNumber ||
        !this.price ||
        !this.squareMeters ||
        !this.type ||
        !this.level
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    "$store.state.property.selectedPropertyId": async function () {
      const propertyId = this.$store.getters["property/selectedPropertyId"];

      this.property = await api.get(propertyId);

      this.fillPropertyParams();
    },
  },
  methods: {
    fillPropertyParams() {
      this.status = this.property.status
        ? this.property.property_status.name
        : null;

      if (
        this.property.status !== "AVAILABLE" &&
        this.property.status !== "BLOCKED"
      ) {
        this.activeModifyStatus = false;
      } else this.activeModifyStatus = true;

      this.price = this.property.price;
      this.unitNumber = this.property.unit_number;
      this.squareMeters = this.property.total_area;
      this.level = this.property.level;

      this.type = capitalize(this.propertyAttribute("PARKING_TYPE"));
      this.towerNumber = this.propertyAttribute("TOWER_NUMBER") || "";
    },

    async updateProperty() {
      try {
        const propertyId = this.$store.getters["property/selectedPropertyId"];
        const type = this.type.value
          ? this.type.value
          : this.type.toUpperCase();

        await api.updatePropertyExtraAttributes(
          propertyId,
          "PARKING_TYPE",
          type
        );

        await api.updateProperty(propertyId, {
          unitNumber: this.unitNumber,
          price: String(parseFloat(this.price)),
          status: this.activeModifyStatus
            ? this.status.value
            : this.property.status,
          totalArea: parseFloat(this.squareMeters),
          level: String(this.level),
          tower: this.towerNumber,
        });

        this.$vs.notify({
          time: 6000,
          title: `${this.unitNumber}! 🏢🚀`,
          text: `Fue actualizado correctamente.`,
          color: "success",
        });
        this.$emit("closePopUp");
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible actualizar la propiedad`,
          text: `No ha sido posible actualizar la propiedad`,
          color: "danger",
        });
      }
    },
    propertyAttribute(attributeValue) {
      const attribute = this.property.property_attribute_values.find(
        (propertyValue) => propertyValue.property_attribute == attributeValue
      );

      if (!attribute) return null;

      return attribute.value;
    },
  },
};
</script>

<style lang="scss">
.fa-money-bill:before,
.fa-bed:before,
.fa-warehouse:before,
.fa-toilet:before,
.fa-bed:before,
.fa-parking:before,
.fa-door-closed:before,
.fa-door-open:before,
.fa-tshirt:before,
.fa-mitten:before,
.fa-tag:before,
.fa-dollar-sign:before,
.fa-seedling:before,
.fa-hand-holding-usd:before,
.fa-vector-square:before,
.fa-person-booth:before,
.fa-plus-square:before,
.fa-flag:before,
.fa-lock:before {
  margin: 4px;
}

.vs__dropdown-toggle {
  height: 46.777px;
}
</style>