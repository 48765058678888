<template>
  <div>
    <vx-card>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h3 class="mt-4 mb-4">
          Nuevo {{ $tc("message.parking", 1, user.countryCode) }}
        </h3>
      </vs-col>
      <br />
      <div class="p-2">
        <vs-row>
          <vs-col>
            <vs-chip color="success" class="m-2">Paso 1</vs-chip>
            <div>
              <h6 class="mt-3">Información basica:</h6>
            </div>
          </vs-col>
        </vs-row>
        <vs-divider></vs-divider>
      </div>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1 p-1">
          <div class="w-full sm:w-full">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-tag"
              :label="`Identificador o número de ${$tc(
                'message.parking',
                1,
                user.countryCode
              )}`"
              color="primary"
              v-model="unitNumber"
              v-validate="'required'"
              name="Nombre_Proyecto"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-building"
              label="Número de torre"
              v-model="towerNumber"
              name="towerNumber"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-money-bill"
              label="Metraje"
              color="primary"
              type="number"
              min="0"
              v-model.number="squareMeters"
              v-validate="'required'"
              name="Metraje"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <p class="vs-input--label">
              Seleccione tipo de
              {{ $tc("message.parking", 1, user.countryCode) }}:
            </p>
            <v-select
              class="px-2"
              taggable
              v-model="type"
              :options="attributesTypes"
              :create-option="(type) => ({ label: type, value: null })"
            ></v-select>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              label="Nivel"
              color="primary"
              min="0"
              v-model="level"
              v-validate="'required'"
              name="Nivel"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full lg:w-full p-1">
          <div class="w-full sm:w-full mt-2">
            <vs-input
              size="large"
              icon-pack="fas"
              class="w-full px-2"
              icon="fa-money-bill"
              label="Precio - Ingresar valor sin comas"
              color="primary"
              type="number"
              min="0"
              v-model.number="price"
              v-validate="'required'"
              name="Precio"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="sm:w-full">
          <div class="w-full mt-2 px-2">
            <span>Seleccione Estado</span>
            <v-select v-model="status" :options="propertiesStatus"></v-select>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col>
          <br />
          <vs-row>
            <vs-col class="sm:w-full lg:w-full p-1">
              <vs-button
                color="success"
                @click="createParking()"
                :disabled="!fieldsReady"
                icon-pack="feather"
                icon="icon-save"
                type="filled"
                ref="loadableButton"
                id="button-with-loading"
                class="vs-con-loading__container w-full"
                >Guardar {{ $tc("message.parking", 1, user.countryCode) }}
                {{ unitNumber }}</vs-button
              >
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";
import api from "../services/property.api";

export default {
  data() {
    return {
      unitNumber: "",
      price: 0,
      squareMeters: 0,
      type: "",
      attributesTypes: [],
      towerNumber: "",
      level: "",
      propertiesStatus: [],
      status: "",
    };
  },
  components: {
    "v-select": vSelect,
  },
  async mounted() {
    const propertiesStatus = await api.getPropertyStatus();
    const projectId = this.$store.getters["project/currentProject"];
    this.propertiesStatus = propertiesStatus.filter(
      (status) => status.id === "AVAILABLE" || status.id === "BLOCKED"
    );
    this.propertiesStatus = this.propertiesStatus.map((status) => ({
      label: status.name,
      value: status.id,
    }));

    const parkingTypes = await api.parkingTypes(projectId);
    if (parkingTypes.length) {
      this.attributesTypes = parkingTypes.map((obj) => {
        return { label: obj, value: obj };
      });
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    fieldsReady() {
      if (
        !this.unitNumber ||
        !this.price ||
        !this.squareMeters ||
        !this.level ||
        !this.status
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async createParking() {
      const projectId = this.$store.getters["project/currentProject"];
      try {
        await api.createExtraProperty(projectId, {
          type: "PARKING",
          status: this.status.value || "BLOCKED",
          unitNumber: this.unitNumber,
          level: this.level || "1",
          tower: this.towerNumber,
          price: this.price,
          totalArea: this.squareMeters,
          parkingType: this.type.value
            ? this.type.value.toUpperCase()
            : this.type.label
            ? this.type.label.toUpperCase()
            : "SIMPLE",
        });

        this.$vs.notify({
          time: 6000,
          title: `${this.unitNumber} creado! 🏢🚀`,
          text: `Fue creado correctamente.`,
          color: "success",
        });

        this.unitNumber = "";
        this.price = 0;
        this.squareMeters = 0;
        this.level = "";
        this.towerNumber = "";
        this.status = "";
        this.type = "";

        this.$emit("closePopUp");
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible crear la propiedad`,
          text: `No ha sido posible crear la propiedad`,
          color: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.fa-money-bill:before,
.fa-bed:before,
.fa-warehouse:before,
.fa-toilet:before,
.fa-bed:before,
.fa-parking:before,
.fa-door-closed:before,
.fa-door-open:before,
.fa-tshirt:before,
.fa-mitten:before,
.fa-tag:before,
.fa-dollar-sign:before,
.fa-seedling:before,
.fa-hand-holding-usd:before,
.fa-vector-square:before,
.fa-person-booth:before,
.fa-plus-square:before,
.fa-flag:before,
.fa-lock:before {
  margin: 4px;
}

.vs__dropdown-toggle {
  height: 46.777px;
}
</style>
