<template>
  <div class="w-full">
    <div class="p-2">
      <vs-popup
        :title="`Ingreso de ${$tc( 'message.parking', 1, user.countryCode)}`"
        :active.sync="popupActive"
      >
        <CreateParkingForm @closePopUp="closePopUp" />
      </vs-popup>
      
        <vs-row vs-type="flex" vs-justify="flex-end">
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="12">
            <vs-button
              v-show="user.role === 'ADMIN'" 
              @click="popupActive = true"
              class="mb-4 mr-2"   
              type="border"
              color="#1B54EB"
              icon-pack="feather"
              title="Agregar parqueo"
              icon="icon-plus-circle"
            >
            </vs-button>

            <router-link :to="{ name: 'Importador de Parqueos con asignacion' }">
              <vs-button
                color="#1B54EB"
                class="mb-4 mr-2"   
                type="border"
                icon-pack="fas"
                icon="fa-wave-square"
                >Asignador a propiedad
              </vs-button>
            </router-link>

            <vs-button
              v-show="user.role === 'ADMIN'"
              v-if="showActionsBySelectedRange"
              type="border"
              color="primary"
              icon-pack="fas"
              icon="fa-edit"
              class="mb-4 mr-2"
              @click="getSelectedRows()"
              >Editar ({{ selected.length }}) registros
            </vs-button>

            <vs-button
              v-show="user.role === 'ADMIN'"
              v-if="showActionsBySelectedRange"
              type="border"
              color="danger"
              icon-pack="fas"
              icon="fa-trash"
              class="mb-4 mr-2"
              @click="showPopupDeleteAll = true"
              >Eliminar ({{ selected.length }}) registros
            </vs-button>

            <vs-button
              v-if="showActionsBySelectedRange"
              type="border"
              icon-pack="fas"
              icon="fa-download"
              class="mb-4 mr-2"
              @click="downloadItemsToExcel()"
              >Descargar registros
            </vs-button>

            <vs-button
            color="#00BF72"
            icon-pack="fas"
            icon="fa-file-excel"
            class="mb-4 mr-2"
              @click="toggleImporter"
              >Importador de {{$tc('message.parking', 2, user.countryCode)}}
            </vs-button>

          </vs-col>
        </vs-row>

      <vx-card 
        v-if="showImporter"
        class="mt-2"
        :title="`🚗 🚗 Importador masivo de ${$tc('message.parking', 2, user.countryCode)}.`"title-color="black">
          <p class="mb-1">
            Si desea un archivo base con las columnas necesarias, puede descargar el
            archivo pulsando el botón de archivo base.
          </p>
          <p>
            🏸 Verfique el archivo base, en el cual encontrara un ejemplo de como
            ingresar sus {{$tc('message.parking', 2, user.countryCode)}} de forma fácil.
          </p>

        <vs-button
          color="primary"
          icon-pack="fas"
          icon="fa-file-excel"
          class=""
          href="https://flattlo-app.s3.amazonaws.com/base-download-files/PARQUEOS_Flattlo.xlsx"
          type="flat"
          >&nbsp;Descargar archivo base
        </vs-button>
      </vx-card>

       <ParkingsImporter v-if="showImporter" :parentTrigger="this.refresh" class="mt-3" />
    </div>

    <div v-show="true" id="data-list-thumb-view" class="data-list-container">
      <DataTable
        :headers="headers"
        :itemsData="parkings"
        :itemPerPage="itemsPerPage"
        :totalItems="totalParkings"
        @serverSideMethod="loadParkings"
        :itemsSelected="selected"
        :load="loading"
        @changeSelect="onSelectionChanged"
        sortBy="number"
        :sortAsc="true"
      >
      </DataTable>
    </div>
    <div>
      <!-- the popUp components -->
      <vs-popup
        :title="`Actualización de ${$tc(
          'message.parking',
          1,
          user.countryCode
        )}`"
        :active.sync="editPopUpActive"
      >
        <UpdateParkingForm @closePopUp="closeEditPopUp" />
      </vs-popup>
      <vs-popup
        :title="`Borrar ${$tc('message.parking', 1, user.countryCode)}`"
        :active.sync="deletePopUpActive"
      >
        <vs-row>
          <h5 class="w-100">
            Se eliminará {{ $tc("message.parking", 1, user.countryCode) }} y
            cotizaciones. ¿Deseas eliminar?
          </h5>

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              color="danger"
              @click.native="deleteParking()"
              class="mt-2"
              icon-pack="feather"
              icon="icon-trash"
              >Sí, eliminar</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
      <vs-popup title="Asignar a propiedad" :active.sync="assignPopupProperty">
        <PopupProperty :propertyAssigned="propertyToAssign" />
      </vs-popup>
      <vs-popup
        title="Actualización masiva"
        :active.sync="showPopUpBySelectedRange"
      >
        <UpdateAllPropertyForm
          :properties="selected"
          @closePopup="closePopUpBySelected"
        />
      </vs-popup>
    </div>
    <vs-popup title="Eliminación masiva" :active.sync="showPopupDeleteAll">
      <vs-row class="justify-center">
        <p>Se eliminarán {{ selected.length }} propiedades.</p>
        &nbsp;&nbsp;
        <p>¿Deseas continuar?</p>
      </vs-row>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="danger"
            @click.native="deleteAllProperties(selected)"
            class="vs-con-loading__container mt-2"
            icon-pack="feather"
            icon="icon-trash"
            id="delete-all-btn"
            >Sí, eliminar</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CreateParkingForm from "./CreateParkingForm";
import UpdateParkingForm from "./UpdateParkingForm";
import { propertyApi, propertySubscription } from "../services";
import PopupProperty from "./PopupProperty";
import currencies from "../../../helpers/currencies";
import UpdateAllPropertyForm from "./UpdateAllPropertyForm.vue";
import { sentryCaptureException } from "../../../helpers/Sentry";
import DataTable from "../../../components/DataTable.vue";
import XLSX from "xlsx";
import importAndAssign from "../components/ImportAndAssignParking";
import ParkingsImporter from "./ImportadorMasivoParqueos.vue";

export default {
  data() {
    return {
      loading: true,
      parkings: [],
      itemsPerPage: 10,
      selected: [],
      popupActive: false,
      editPopUpActive: false,
      deletePopUpActive: false,
      property: null,
      assignPopupProperty: false,
      propertyToAssign: null,
      showActionsBySelectedRange: false,
      showPopUpBySelectedRange: false,
      showPopupDeleteAll: false,
      headers: [],
      totalParkings: 20,
      showImporter: false,
    };
  },
  components: {
    CreateParkingForm,
    UpdateParkingForm,
    PopupProperty,
    UpdateAllPropertyForm,
    DataTable,
    importAndAssign,
    ParkingsImporter
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  async beforeMount() {
    this.headers = [
      {
        text: "Unidad",
        value: "unit_number",
        width: 130,
        align: "left",
        filterValue: { type: null, value: "" },
        filter: true,
      },
      {
        text: "Precio.",
        value: "price",
        width: 170,
        align: "left",
        filterType: "Number",
        filterValue: { type: "", value: "" },
        filter: true,
        cellRenderer: "composed",
        cellRendererParams: (item) => this.getFormatCurrency(item),
      },
      {
        text: "Nivel",
        value: "level",
        align: "left",
        filterType: "Number",
        filterValue: { type: "", value: "" },
        filter: true,
        width: 120,
      },
      {
        text: "Metraje",
        value: "total_area",
        width: 130,
        align: "left",
        filterType: "Number",
        filterValue: { type: "", value: "" },
        filter: true,
        cellRenderer: "composed",
        cellRendererParams: (item) => `${item.total_area} m`,
      },
      {
        text: "Tipo.",
        value: "PARKING_TYPE",
        width: 120,
        align: "left",
        sortable: false,
        cellRenderer: "composed",
        filterType: "Attribute",
        filterValue: { type: "", value: "" },
        filter: true,
        cellRendererParams: (item) => {
          return (
            this.propertyAttribute(
              item.property_attribute_values,
              "PARKING_TYPE"
            ) || "-"
          );
        },
      },
      {
        text: "Torre.",
        value: "TOWER_NUMBER",
        width: 120,
        align: "left",
        cellRenderer: "composed",
        filterType: "Attribute",
        filterValue: { type: "", value: "" },
        filter: true,
        sortable: false,
        cellRendererParams: (item) => {
          return (
            this.propertyAttribute(
              item.property_attribute_values,
              "TOWER_NUMBER"
            ) || "-"
          );
        },
      },
      {
        text: "Unidad Asignada",
        value: "unit_asigned",
        width: 120,
        cellRenderer: "composed",
        cellRendererParams: (item) => {
          if (item.assignedPropertiesByPropertyAssignedId.length > 0) {
            return item.assignedPropertiesByPropertyAssignedId[0]
              .propertyByPropertyId.unit_number;
          } else {
            return "-";
          }
        },
        align: "left",
      },
      {
        text: "Estado",
        value: "status",
        width: 180,
        align: "left",
        cellRenderer: "flat",
        filterType: "Estado",
        filterValue: { type: null, value: "" },
        filter: true,
        filterStatus: [
          { name: "Disponible", value: "AVAILABLE" },
          { name: "Reservado", value: "RESERVED" },
          { name: "Asignado", value: "ASSIGNED" },
          { name: "Bloqueado", value: "BLOCKED" },
          { name: "Opcionado", value: "OPTIONED" },
          { name: "Prometido", value: "PROMISED" },
          { name: "Vendido", value: "SOLD" },
        ],
        cellRendererParams: (item) => this.getStatus(item),
      },
      {
        text: "Acciones",
        value: "actions",
        align: "left",
        hide: this.user.role === "SUPERVISOR" ? true : false,
        cellRenderer: "actions",
        cellRendererParams: (item) => {
          const buttons = [];
          if (item.status === "AVAILABLE") {
            buttons.push({
              color: "#F24646",
              icon: "icon-lock",
              title: "Bloquear",
              event: () => {
                this.blockItem(item);
              },
            });
          }

          if (item.status === "BLOCKED") {
            buttons.push(
              {
                color: "#EBB41B",
                icon: "icon-unlock",
                title: "Desbloquear",
                event: () => {
                  this.enableItem(item);
                },
              },
              {
                color: "#00A53D",
                icon: "icon-settings",
                title: "Asignar unidad",
                event: () => {
                  this.assignEvent(item);
                },
              }
            );
          }

          if (item.status === "DESISTED") {
            buttons.push({
              color: "#EBB41B",
              icon: "icon-unlock",
              title: "Desbloquear",
              event: () => {
                this.enableItem(item);
              },
            });
          }

          if (
            item.status !== "RESERVED" &&
            item.status !== "BLOCKED" &&
            item.status !== "DESISTED" &&
            item.status !== "OPTIONED" &&
            this.user.role === "ADMIN"
          ) {
            buttons.push(
              {
                color: "#1973E8",
                icon: "icon-edit",
                title: "Editar",
                event: () => {
                  this.editEvent(item);
                },
              },
              {
                color: "#00A53D",
                icon: "icon-settings",
                title: "Asignar unidad",
                event: () => {
                  this.assignEvent(item);
                },
              },
              {
                color: "#F24646",
                icon: "icon-trash",
                title: "Eliminar",
                event: () => {
                  this.deleteEvent(item);
                },
              }
            );
          }

          return buttons;
        },
        sortable: false,
        width: 140,
      },
    ];
  },
  async mounted() {
    const projectId = this.$store.getters["project/currentProject"];
    const totalParkings = await propertyApi.totalPropertyList(
      projectId,
      "PARKING"
    );
    this.totalParkings = totalParkings;
    this.$root.$on("close-popup", () => {
      this.assignPopupProperty = false;
    });
    this.$root.$on("reload-parking-props", () => {
      if (!this.parkings.length) this.loadParkings();
    });
  },
  methods: {
    toggleImporter() {
      this.showImporter = !this.showImporter
    },
    async refresh() {
      this.toggleImporter();
      if ((typeof this.parentTrigger) == "function") {
        this.parentTrigger();
      }
      await this.$refs.listParkings.loadParkings();
    },
    async loadParkings(options) {
      const { projectId } = this.$route.params;
      const vueScope = this;
      this.$store.dispatch("project/currentProject", { projectId });

      const { ordering, pagination, filtering } = options;

      const parkings = await propertySubscription.onPropertyListChange(
        projectId,
        "PARKING",
        ordering,
        pagination,
        filtering
      );

      this.loading = true;
      parkings.subscribe({
        next(p) {
          vueScope.parkings = p.data ? p.data.property : [];
          vueScope.loading = false;
        },
        error(e) {
          console.log(e);
          sentryCaptureException(e, "parkings");
        },
      });
    },
    getStatus(data) {
      return {
        color: data.property_status
          ? this.statusColor(data.property_status.id)
          : null,
        value: data.property_status ? data.property_status.name : null,
      };
    },
    statusColor(status) {
      if (status == "AVAILABLE") return "green";
      if (status == "RESERVED") return "red";
      if (status == "ASSIGNED") return "dark";
    },
    async closePopUp() {
      this.popupActive = false;
    },
    async closeEditPopUp() {
      this.editPopUpActive = false;
      this.showActionsBySelectedRange = false;
    },
    async deleteParking() {
      try {
        await propertyApi.deleteProperties(this.property.id);

        this.deletePopUpActive = false;
        this.showActionsBySelectedRange = false;

        this.$vs.notify({
          time: 6000,
          title: `Propiedad ${this.property.unit_number} 🏢🚀.`,
          text: `Eliminada correctamente.`,
          color: "success",
        });
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible eliminar propiedad.`,
          text: `No ha sido posible eliminar propiedad.`,
          color: "danger",
        });
      }
    },
    getFormatCurrency(property) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];

      return currencies.currencyFormatter({
        value: property.price,
        currency: currencySymbol,
      });
    },
    editEvent(data) {
      this.$store.dispatch("property/setSelectedPropertyId", {
        propertyId: data.id,
      });
      this.editPopUpActive = true;
    },
    assignEvent(data) {
      this.propertyToAssign = data;
      this.assignPopupProperty = true;
    },
    deleteEvent(data) {
      this.property = data;
      this.deletePopUpActive = true;
    },
    onSelectionChanged(selected) {
      this.selected = selected;
      !this.selected.length
        ? (this.showActionsBySelectedRange = false)
        : (this.showActionsBySelectedRange = true);
    },
    getSelectedRows() {
      this.showPopUpBySelectedRange = true;
    },
    async closePopUpBySelected() {
      this.showPopUpBySelectedRange = false;
      this.showActionsBySelectedRange = false;
      this.selected = [];
    },
    propertyAttribute(attributeValues, value) {
      const attribute = attributeValues.find(
        (propertyValue) => propertyValue.property_attribute == value
      );

      if (!attribute) return null;

      return attribute.value;
    },
    async deleteAllProperties(properties) {
      try {
        this.$vs.loading({
          background: "danger",
          color: "#fff",
          container: "#delete-all-btn",
          scale: 0.45,
        });

        const propsToDelete = properties.map((i) => i.id);
        const promisesDelete = propsToDelete.map(async (id) => {
          return propertyApi.deleteProperties(id);
        });
        await Promise.all(promisesDelete);

        this.showPopupDeleteAll = false;
        this.showActionsBySelectedRange = false;
        this.selected = [];

        this.$vs.loading.close("#delete-all-btn > .con-vs-loading");
        this.$vs.notify({
          time: 6000,
          title: `Propiedades eliminadas 🏢🚀.`,
          text: `Se han eliminado ${propsToDelete.length} propiedades`,
          color: "success",
        });
      } catch (e) {
        this.$vs.loading.close("#delete-all-btn > .con-vs-loading");
        this.showPopupDeleteAll = false;

        this.$vs.notify({
          title: `No ha sido posible eliminar propiedades.`,
          text: `Se ha comunicado a soporte técnico`,
          color: "danger",
        });
      }
    },
    async blockItem(data) {
      if (data.assignedPropertiesByPropertyAssignedId.length) {
        this.$vs.notify({
          title: `No ha sido posible bloquear unidad ${data.unit_number}.`,
          text: `Esta unidad se encuentra asignada.`,
          color: "danger",
        });

        return;
      }

      try {
        await propertyApi.blockProperty(data.id);

        this.$vs.notify({
          time: 6000,
          title: `Propiedad bloqueda 🏢🚀.`,
          text: `Se han bloqueado la unidad ${data.unit_number}`,
          color: "success",
        });
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible bloquear unidad ${data.unit_number}.`,
          text: `Se ha comunicado a soporte técnico`,
          color: "danger",
        });
      }
    },
    async enableItem(data) {
      if (data.assignedPropertiesByPropertyAssignedId.length) {
        this.$vs.notify({
          title: `No ha sido posible desbloquear unidad ${data.unit_number}.`,
          text: `Esta unidad se encuentra asignada.`,
          color: "danger",
        });

        return;
      }

      try {
        await propertyApi.enableProperty(data.id);

        this.$vs.notify({
          time: 6000,
          title: `Propiedad desbloqueda 🏢🚀.`,
          text: `Se han desbloqueado la unidad ${data.unit_number}`,
          color: "success",
        });
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible desbloquear unidad ${data.unit_number}.`,
          text: `Se ha comunicado a soporte técnico`,
          color: "danger",
        });
      }
    },
    downloadItemsToExcel() {
      const headersFiltered = this.headers.filter(
        (header) => header.text !== "Acciones"
      );
      const data = this.selected.map((item) => {
        const row = {};
        headersFiltered.forEach((header) => {
          const { text, value, cellRendererParams } = header;
          if (cellRendererParams) {
            row[text] =  (text==='Estado') ? cellRendererParams(item).value : cellRendererParams(item);
          } else {
            row[text] = item[value];
          }
        });
        return row;
      });
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Parqueos");
      XLSX.writeFile(wb, "parkings.xlsx");
    },
  },
};
</script>
