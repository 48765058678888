<!-- ya no se usa -->
<template>
  <div>
      <vs-row>
        <vs-button
          color="primary"
          type="filled"
          icon-pack="fas"
          icon="fa-file-excel"
          class="m-1"
          @click="toggleImporter"
          >Importador de {{$tc('message.parking', 2, user.countryCode)}}</vs-button
        >
        <router-link
          :to="{ name: 'Importador de Parqueos con asignacion' }"
        >
          <vs-button
            color="warning"
            text-color="#000"
            type="filled"
            icon-pack="fas"
            icon="fa-wave-square"
            class="m-1"
            >Asignador a propiedad</vs-button
          >
        </router-link>
      </vs-row>
      <ParkingsImporter v-if="showImporter" :parentTrigger="this.refresh" class="mt-10" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ParkingsImporter from "./ImportadorMasivoParqueos.vue";

export default {
  props: {
    parentTrigger: undefined
  },
  data() {
    return {
      showImporter: false
    }
  },
  components: {
    ParkingsImporter
  },
  computed: {
    ...mapState("auth", ["user"])
  },
  methods: {
    toggleImporter() {
      this.showImporter = !this.showImporter
    },
    refresh() {
      this.toggleImporter();
      if ((typeof this.parentTrigger) == "function") {
        this.parentTrigger();
      }
    }
  }
};
</script>
