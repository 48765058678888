<template>
    <listadoParqueos ref="listParkings" />
</template>

<script>
import listadoParqueos from "../components/ListParkings";
import { api } from "../../projects/services";
import { mapState } from "vuex";

export default {
  components: {
    listadoParqueos
  },
  data() {
    return {
      project: null
    };
  },
  async mounted() {
    const projectId = this.$store.state.project.currentProjectId;
    this.project = await api.get(projectId);
    this.$store.state.project.breadcrumbs = [
      { 
        pageName: 'Edición de proyectos', 
        route: '/proyectos' 
      },
      {
        pageName: `${this.capitalizeFirstLetter} en  ${this.project.name}`,
        route: `/parqueos/${this.$route.params.projectId}`
      }
    ];
  },
  computed: {
    ...mapState("auth", ["user"]),
    capitalizeFirstLetter() {
      let str = this.$tc('message.parking',2,this.user.countryCode);
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  methods: {
  }
};
</script>
